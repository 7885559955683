import './App.css';
import '../themes/lightTheme.js';

import ActionAreaCard from '../card/card';
import cardDataList from "../cardDataList";
import {Draggable} from 'drag-react';

import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Link from '@mui/material/Link';

import { createTheme, ThemeProvider, Paper, Grid, Switch, Stack, styled} from "@mui/material";
import Divider from '@mui/material/Divider';
import lightTheme from '../themes/lightTheme.js';
import darkTheme from '../themes/lightTheme.js';
import { useMediaQuery } from '@mui/material';
import {useState, useEffect, View} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import About from '../components/about';
import Landing from '../components/landing';
import SmallContent from '../components/smallContent';
import CustomDivider from '../components/customDivider.js';

function Projects() {
  const isMobile = useMediaQuery(lightTheme.breakpoints.down('sm'));
  const isHalf = useMediaQuery(lightTheme.breakpoints.down('lg'));
  const [cardData, setCardData] = useState([]);
  const [sortType, setSortType] = useState('relevance');


  useEffect(() => {
    const sortArray = type => {
      const types = {
        relevance: 'relevance',
        date: 'endDate',
      };
      const sortProperty = types[type];
      const sorted = [...cardDataList].sort((a, b) => b[sortProperty] - a[sortProperty]);
      setCardData(sorted);
    };

    sortArray(sortType);
  }, [sortType]); 

  return (
    <ThemeProvider theme={lightTheme} >
      <Container maxWidth={false} sx={{ width:'100%', m:0, p:0, backgroundImage: `url(${process.env.PUBLIC_URL}/bgPatterns/pattern_2.webp)`,
                                        backgroundRepeat: 'repeat',
                                        }}>

        <SmallContent
            variant="h3"
            verticalSpacing={20}
            text={"Here you find some of the projects I've worked on, in a team, or solo."}
            fromRight={true}
        />                                            
        {isMobile ? null : (<Divider textAlign="left" flexItem sx={{fontSize:isMobile ? '13px' : '17px', m: 0, backgroundColor: '#00000000', '&.MuiDivider-root': { '&::before': { borderTop: `4px solid ${lightTheme.palette.secondary.main}`, }, 
                                                                                                            '&::after': { borderTop: `4px solid ${lightTheme.palette.secondary.main}`, }}}} >
            sort projects by:&nbsp;
            <FormControl flexItem sx={{ m: 0, p:0, height:20 }} size="small">
            <InputLabel id="demo-simple-select-label" >Sort</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortType}
              label="sort"
              sx={{ m: 0, p:0, height:20 }}
              onChange={(e) => setSortType(e.target.value)}
            >
              <MenuItem size="small" value="relevance">Relevance</MenuItem>
              <MenuItem size="small" value="endDate">Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
            </Select>
          </FormControl>
        </Divider>)}
        <Container maxWidth={false}>
          <ImageList cols={isMobile ? 1 : (isHalf ? 2 : 3)}  gap={20} sx={{alignItems:'center', overflowY: "visible", p:0}}>
            {cardData.map((item) => {
              return(
              <ImageListItem key={item.source} sx={{m:0, pt:1, pb:0}}>
                <ActionAreaCard 
                                        name={item.title}
                                        width={item.width}
                                        height={item.height}
                                        title={item.title}
                                        smallTextContent={item.smallTextContent}
                                        largeTextContent={item.largeTextContent}
                                        dateFrom={item.dateFrom}
                                        dateEnd={item.dateEnd}
                                        source={item.source}
                                        programmingChips={item.programmingChips}
                                        theme={lightTheme}
                                         />
              </ImageListItem>)})}
          </ImageList>
        </Container>
    
        <Box sx={{ height: '10px' }} />
    </Container>
    </ThemeProvider>
  );
}
export default Projects;
