import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import Landing from './landing';
import CustomDivider from './customDivider';
import lightTheme from '../themes/lightTheme';

function About() {
  return (
    <Container maxWidth={false} sx={{ width: '100%' }}>
      <Landing />
      <CustomDivider startWidth={0} endWidth={100} color={lightTheme.palette.secondary.main} />
      <Container maxWidth="md" id="about">
        <Box sx={{ my: 50 }}>
          <Typography variant="h2" gutterBottom>
            (About)
          </Typography>
          <Typography variant="h6" paragraph>
            As a passionate game developer and software engineer I've worked on various projects that combine my love for gaming, technology, and the art of creation.
            (GameSome) is the game studio dedicated to creating mere entertainment games & other applied interactive media that focuses on 
            bettering humans and their lives, harnessing the power of fun to address real-world challenges and inspire positive change by 
            bridging entertainment and goal-oriented design to create impactful sparks of joy, one game at a time.
          </Typography>
        </Box>
      </Container>
    </Container>
  );
}

export default About;