import React, { useRef, useEffect, useState } from 'react';
import { Typography, Container, Box } from '@mui/material';
import { keyframes } from '@mui/system';

const swipeInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const swipeInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

function SmallContent({ 
  id = "undefined",
  verticalSpacing = 16, 
  text = "Some content line goes here buddy!",
  variant = "h3",
  duration = 1,
  fromRight = true // New prop to determine direction
}) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1 // Trigger when 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const animationKeyframes = fromRight ? swipeInFromRight : swipeInFromLeft;
  const initialTransform = fromRight ? 'translateX(100%)' : 'translateX(-100%)';

  return (
    <Container maxWidth="md" id={id} sx={{ overflow: 'hidden' }}>
      <Box sx={{ my: verticalSpacing, textAlign: 'center' }} ref={ref}>
        <Typography 
          variant={variant} 
          gutterBottom 
          align="center"
          sx={{
            animation: isVisible ? `${animationKeyframes} ${duration}s ease-out` : 'none',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : initialTransform,
            transition: `opacity ${duration}s, transform ${duration}s`,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Container>
  );
}

export default SmallContent;